.container {
  padding: 24px 20%;
  overflow: hidden;
  overflow-y: auto;
  // см. RateAddDialog.module.scss .scrollable
  max-height: calc(100vh - 134px - 16px);
}

.checkbox-wrapper {
  width: 24px;
  height: 24px;
  display: flex;
}
