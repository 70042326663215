body {
  font-family: 'Segoe UI', sans-serif;
  font-size: 15px;
  line-height: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll; // чтобы показ/скрытие скроллбара не сдвигало контент, скроллбар показывается всегда
  // чтобы не было горизонтального сдвига
  max-width: 100%;
  overflow-x: hidden;
}

input {
  padding: 9px 12px;
}

input, button, a {
  // фикс для firefox
  &:focus-visible {
    outline: none;
  }
}

.text-link {
  color: $blue;
}
