.btn {
  &-primary {
    color: $white;

    &:focus, &:active {
      color: $white;
    }
  }

  &-link {
    color: $blue;
    text-align: left;
    line-height: 20px;
    text-decoration: underline dashed;
    text-underline-offset: 4px;
    padding: 0;
    border-width: 0;

    &-flush {
      text-decoration: none;
      text-underline-offset: 0;
    }

    &:hover, &:focus {
      color: shift-color($blue, $link-shade-percentage);
      box-shadow: none;
      outline: none;
    }

    &:disabled {
      color: shift-color($blue, $link-shade-percentage);
      opacity: .5;
    }
  }
}
